<template>
  <div v-if="organism.iframe" :class="[
      'container-' + organism.containerWidth, ,
      'relative overflow-hidden organism simple-iframe md:px-0 px-4',
    ]" :style="'background-color:' + organism.backgroundColor">
    <div class="relative content flex items-center justify-center flex-col text-center mx-auto bg-secondary-500">
      <h2 v-if="organism.title1" :style="'color:' + organism.titleColor"
        class="title1 mt-16 mb-3 font-cg text-3xl sm:text-[3.5rem] sm:leading-[3.5rem] font-semibold text-white">
        {{ organism.title1 }}
      </h2>
      <p v-if="organism.title2" class="mb-9 max-w-4xl title2 font-figtree"
        :style="'color:' + organism.titleColor">
        {{ organism.title2 }}
      </p>
    </div>
    <div class="mx-auto max-w-screen-xl iframe-content" ref="iframeContainer" v-html="organism.iframe"></div>
  </div>
</template>

<script setup>
const props = defineProps({
  organism: Object,
});


const iframeContainer = ref(null);

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>
